.section1
    overflow: hidden

    &.full
        background-image: url("../../public/img/bg-1.png")
        min-height: 100vh
    background-color:  #ffffff5a
    .bg-body-tertiary 
        background: transparent !important
        .navbar-brand
            height: 80px
            img 
                height: 100%
                width:  100%

        .offcanvas-body 
            .menu
                display: flex
                max-width: 613px
                margin: auto
                .nav-link
                    margin: auto 32px
                    position: relative
                    padding-bottom: 1rem
                    &::before
                        content: ""
                        position: absolute
                        bottom: 0
                        left: 50%
                        width: 0
                        height: 2px
                        background-color: $primary  // Couleur de la barre de soulignement
                        transition: width 0.3s ease-in-out, left 0.3s ease-in-out

                    &:hover::before
                        width: 100%
                        left: 0
                    &.active
                        color: $primary
                        border-bottom: solid 2px $primary


    .text
        .title
            display: inline-block
            // max-width: 500px
            h1 
                font-size: 65px
                font-family: "Istok Bold"
                line-height: 65px
                span
                    color: $primary
            position: relative


        .description
            position: relative
            padding-left:  21px
            padding-top:  21px
            padding-bottom:   21px
            &::before
                content: ""
                width: 2px
                height: 100%
                background: $black
                left: 0
                top: 0
                position: absolute
            p 
                font-weight: 400
                margin-bottom: 34px
            .buttons
                &:first-child
                    padding: 5rem
                    background: red
    .img    
        img
            width: 100% 
            height: 100%


.smill
    right: -6rem
    position: absolute
    bottom: 0rem
    width: 100px
    height: 100px
    transition: .5s
    svg
        width: 100%
        transition: .5s
        height: 100%
        transition: transform 1s ease-in-out
        .eye
            animation: blink 1s 1
        .eye-right
            position: relative
            &::before
                content: ""
                background: red 
                bottom: 0 
                width: 100px
                height: 1px
                position: absolute
        &.blink .eye
            animation: blink 1s 1
        .mouth-closed
            display: none
        &:hover
            transform: scale(1.1) rotate(-20deg)
            .mouth-open
                display: block
            .mouth-closed
                display: block

@keyframes blink
  50%
    transform: scaleY(0.3)

.mobile-menu
    padding: 2rem 0
    
    a 
        text-decoration: none
        color: $black
    ul
        padding-left: 0
        list-style: none
        .active
            li
                border: 1px solid #FFE0A7
                background: #FFF1D3

        li  
            margin: 2rem 0
            padding: .5rem .5rem .5rem 2rem
            transition: .2s ease-in-out
            &:hover
                background: #FFF1D3
@media  (max-width: 992px)
    .section1 
        .text
            .title
                h1 
                    font-size: 2em
                    line-height: 45px

                .smill 
                    width: 75px
                    height: 75px
    .offcanvas-body
        .menu
            a 
                margin-bottom: 1rem
            .nav-link
                margin: auto 32px 2rem 32px
                position: relative
                padding-bottom: .5rem
                display: inline-block
                &::before
                    content: ""
                    position: absolute
                    bottom: 0
                    left: 50%
                    width: 0
                    height: 2px
                    background-color: $primary  // Couleur de la barre de soulignement
                    transition: width 0.3s ease-in-out, left 0.3s ease-in-out

                &:hover::before
                    width: 100%
                    left: 0
                &.active
                    color: $primary
                    border-bottom: solid 2px $primary

        .btn-1
            width: 100%