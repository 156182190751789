.statut-card
    height: 100vh
    display: flex
    justify-content: center
    padding: 2rem 0
    .verif-card
        display: grid
        text-align: center
        justify-content: center
        height: 100vh
        // background: red
        position: relative
        .text 
            min-height: 537px
            box-shadow: 5px 5.8px 71px 0px rgba(0, 0, 0, 0.23)
            background: url("../../public/img/bg-verif.svg"), #fff
            background-size: cover
            background-position: center
            .tout
                background: #fffffff4
                padding: 2rem
                display: block
                width: 100%
                min-height: 370px
                .statut-title 
                    h4 
                        margin-bottom: 1rem

        .content
            ul 
                padding-left: 0
                list-style: none
                li 
                    display: flex
                    justify-content: space-between
                    margin-bottom: 1rem
                    .span2 
                        font-family: "Istok Bold"

            .ul1
                padding-top: 2rem 
                border-top: 1px dashed #D9D9D9
            .ul2 
                border-top: 1px dashed #D9D9D9
                border-bottom: 1px dashed #D9D9D9
                padding: 2rem 0
