.new-partenaire
    .content 
        .imgUpload 
            background: #F8F9FA
            padding: 1rem 
            border: 1px dashed #DEE2E6
            display: flex
            align-items: center
            .explication
                margin: auto
                h6 
                    color: $primary
                    font-weight: 400
                p 
                    color: #9499A1
                    font-size: 14px

@media  (max-width: 992px)
    .choix-offre
        .form-check 
            padding-left: 0 !important
            flex-wrap: wrap
            .detailles
                margin-left: 0 !important
                margin-top: 1rem
        .info
            width: 100% !important
            justify-content: space-between