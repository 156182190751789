.contact-form
    border: 1px solid #FFE0A7
    background: #FFF1D3
    box-shadow: 3.1px 19.8px 20px 0px rgba(0, 0, 0, 0.10)
    margin-bottom: 2rem
    .form   
        height: 100%
        padding: 2rem
    .col-lg-8 
        padding-right: 0
    .col-lg-4 
        padding-left: 0
    .rs 
        height: 2rem
        height: 100%
        padding: 2rem
        background: #E5913A
        .title
            display: flex
            justify-content: space-between
            color: #FFF1D3
            font-family: "Istok Regular"
            align-items: center
            .text
                color: #FFF1D3
            .bar 
                background: red
                content: ""
                width: 100px 
                height: 100%
        .icon 
            svg
                background: #FFF1D3
                margin: 0 .5rem
                padding: .3rem
                border-radius: 100%
                color: $black
                transition: .5s
                cursor: pointer
                &:hover
                    background: #E5913A
                    transform: rotate(360deg)
                    box-shadow: 3.1px 19.8px 20px 0px rgba(0, 0, 0, 0.10)
                    color: $white
                &:last-child
                    margin: 0

        .items 
            margin-top: 2rem
            a 
                text-decoration: none
                color: inherit
            .item
                background: #FFF1D3
                box-shadow: 3.1px 19.8px 20px 0px rgba(0, 0, 0, 0.10)
                padding:  1rem
                margin-bottom: 2rem
                font-family: "Istok Bold"
                display: flex
                align-items: center
                position: relative
                overflow: hidden
                transition: .5s
                cursor: pointer
                &::before
                    content: "",
                    top: 0
                    bottom: 0
                    left: 0
                    right: 0
                    background: #E5913A
                    position: absolute
                    width: 100%
                    height: 100%
                    z-index: 1
                    transition: .5s
                    transform: translateY(100%)
                &:hover
                    color: $white
                    box-shadow: 4.1px 19.8px 20px 22px rgba(0, 0, 0, 0.10)
                    &::before
                        transform: translateY(0)
                            
                svg 
                    background: #E5913A
                    padding: .2rem
                    margin-right: 1rem
                    border-radius: 100%
                    color: #FFF1D3
                    z-index: 2
                span
                    z-index: 2

@media  (max-width: 992px)
    .contact-form
        .col-lg-8 
            padding-right: 12px
        .col-lg-4 
            padding-left: 12px