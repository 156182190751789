.abonnement
    .entete
        display: flex
        align-items: center
        justify-content: space-between
        margin: auto
        margin-bottom: 2rem
        h4
            margin-bottom: 0
            padding-bottom: 0
.choix-offre 
    .info 
        background: $black
        color: $white
        .text 
            padding: 1rem
            display: flex
            flex-direction: column
            justify-content: space-between
            input 
                margin: 4px 14px  0  0
            label 
                margin: 0 !important
                font-size: 12px
            p 
                margin-bottom: 0
                font-size: 12px
                font-weight: 400
                opacity: .8
            h4 
                margin-bottom: 0
                font-size: 20px
        .img
            img 
                height: 100%

    .detailles
        .title
            h3 
                font-style: 11px
        ul
            padding-left: 0 
            list-style-type: none
            svg 
                color: #C2F1A7
                background: #398F07
                border-radius: 100%

.use-condition
    p 
        margin-top: 2rem
        font-size: 12px
        color: #666