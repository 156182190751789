
  .progress-wheel {
    transform: rotate(-90deg);
  }
  
  .progress-wheel circle {
    stroke-dashoffset: 0;
    stroke-width: 30%;
    fill: none;
  }
  
  .progress-wheel .bg {
    stroke: $primary;
    opacity: 0.2;
  }
  
  .progress-wheel .progress {
    stroke: $primary;
    stroke-dasharray: 0, 1;
  }

  .svg-container {
    position: relative;
    display: inline-block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: .5s;
  }
  .svg-container.hide {
    transform: translateY(200%);
  }
  .svg-container.show {
    transform: translateY(0%);
  }
  .chevron {
    position: absolute;
    top: 50%; /* Ajustez la valeur en fonction de la position souhaitée */
    right: 50%; /* Centre horizontalement */
    font-size: 30px; /* Taille du chevron */
    font-weight: bold;
    transform: translate(7px, -15px);
    stroke: $primary;
    opacity: 0.2;
  }
  .svg-container:hover .chevron {
    stroke: $primary;
    opacity: 1;
  }
  

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: transparent;
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-thumb {
    background: $primary;
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: #fff3;
  }