body
    font-family: "Istok Regular"
h1, h2, h3, h4, h5, h6
    font-family: "Istok Bold"


.sectionHeader
    margin-bottom: 2rem
    .title
        text-align: center
        h2  
            font-size: 2rem
        p 
            color: $grey
        .content
            max-width: 500px
            margin: auto auto 2rem auto



.lastSection
    background-image: url("../../public/img/bottombg.svg")
    background-size: cover
    padding-bottom: 4rem
    background-repeat: no-repeat
    background-position: center
    background-attachment: fixed
    background-color: $white


.form-control, .form-select
    padding: .5rem 2rem
    border-radius: 0
    background: $white
label 
    font-family: "Istok Regular"
    margin-bottom: .5rem
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active 
    color: $primary !important
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed 
    color: $primary !important
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked 
    color: $primary !important

@media  (max-width: 992px)
    .lastSection
        background-attachment: scroll
