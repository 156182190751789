.error
    background: #FFE0A7
    text-align: center
    padding: 2rem
    border-radius: 5px
    h1 
        font-size: 5rem
        color: #0981c0

.error404
    background: #FFE0A7
    text-align: center
    padding: 2rem
    border-radius: 5px
    height: 100vh
    align-items: center
    display: grid
    h1 
        font-size: 5rem
        color: #F86F03