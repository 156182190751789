.offresection
    margin-bottom: 2rem
    .title
        text-align: center
        h2  
            font-size: 2rem
        p 
            color: $grey
        .content
            max-width: 500px
            margin: auto