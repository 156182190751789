.dashboard
    background: linear-gradient(180deg, #FFA534 0%, #A0400C 100%)
    min-height: 100vh
    .topbar
        background: $white
        padding: .5rem
        .logo
            width: 130px
            img
                width: 100%
                height: 100%
    .sidebar
        background: $white
        padding: 2rem 0
        a 
            text-decoration: none
            color: $black
        ul
            padding-left: 0
            list-style: none
            .active
                li
                    border: 1px solid #FFE0A7
                    background: #FFF1D3

            li  
                margin: 2rem 0
                padding: .5rem .5rem .5rem 2rem
                transition: .2s ease-in-out
                &:hover
                    background: #FFF1D3
                    

    .main
        background: $white
        padding: 2rem 


    .title-section 
        display: flex
        justify-content: space-between
        align-items: center
        h5
            margin-bottom: 0
            

@media  (max-width: 992px)
    .dashboard
        .sidebar
            display: none