@font-face {
    font-family: 'Istok Bold'; // Nom de la police
    src: url('../../public/font/Istok_Web/IstokWeb-Bold.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'Istok BoldItalic'; // Nom de la police
    src: url('../../public/font/Istok_Web/IstokWeb-BoldItalic.ttf') format('truetype')
  }

  @font-face {
    font-family: 'Istok Italic'; // Nom de la police
    src: url('../../public/font/Istok_Web/IstokWeb-Italic.ttf') format('truetype')
  }

  @font-face {
    font-family: 'Istok Regular'; // Nom de la police
    src: url('../../public/font/Istok_Web/IstokWeb-Regular.ttf') format('truetype')
  }