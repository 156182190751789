.single-header 
    background: $white
    min-height: 65vh
    display: flex
    align-items: center
    background-image: url("../../public/img/bg_single1.png")
    background-size: cover
    background-repeat: no-repeat
    background-position: top
    h2, p 
        margin-bottom: 0
    h2 
        font-size: 3rem




@media  (max-width: 992px)
    .single-header 
        min-height: 40vh
        h2 
            font-size: 2rem
