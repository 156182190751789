.presentationSection
    background: $blue
    padding-top: 2rem
    .img    
        // max-width:  643px
        // max-height:  793px

        img 
            width: 100%
            height:  100% 

    .content
        .title
            h2
                font-family: "Istok Bold"
                font-size: 2.7em
                span 
                    color:  $primary
        .text 
            padding: 2rem 0
            p 
                color: $white

            &1
                border-bottom: 1px solid $white
                padding-bottom: 1rem
            &2
                margin-bottom: 0
                padding-bottom: 0



@media  (max-width: 992px)
    .presentationSection
        .content
            .title
                h2
                    font-size: 2.3em
                    text-align: center