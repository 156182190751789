.btn
    padding: 0
    transition: .5s ease-in-out
    &:hover
        transform: scale(1.05)    
    &:active
        transform: scale(.8)    
        
    &.btn-1 
        color: $white
        background: $primary
        padding: .5rem 2rem
        border-radius: 0
        font-family: "Istok Bold"    


    &.btn_submit
        color: $white
        background: $primary
        padding: .5rem 2rem
        border-radius: 0
        font-family: "Istok Bold"       
    &.download
        color: $white
        background: $primary
        padding: .2rem 
        border-radius: 0
        font-size: 12px
        font-family: "Istok Bold"  
    &.btn-ok
        color: $white
        background: #71D875
        padding: .2rem 2rem
        border-radius: 0
        font-family: "Istok Bold"  
    &.btn-false
        color: $white
        background: #000
        padding: .2rem 2rem
        border-radius: 0
        font-family: "Istok Bold"


@media  (max-width: 992px)
    .btn
            
        &.btn-1 
            padding: .5rem     
        &.btn_submit
            padding: .5rem 

        &.btn-ok
            padding: .2rem 
        &.btn-false
            padding: .2rem 