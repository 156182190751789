// .footer
//     background: $white
//     padding: 2rem
//     .form
//         margin: 2rem 0
//     .form-control 
//         display: inline !important
//         width: auto
//         border-radius: 0
//         padding: .5rem 2rem
//         margin-right: 1rem

//     .title 
//         margin: auto 


.footer 
    background: $black
    padding-top:  2rem
    color: $white
    text-align: center
    ul
        display: flex
        justify-content: space-around
        list-style: none
        flex-wrap: wrap
        border-bottom: 1px solid #272626
        li 
            a 
                text-decoration: none
                color: $white
                margin: auto 32px 2rem 32px
                position: relative
                padding-bottom: .5rem
                display: inline-block
                &::before
                    content: ""
                    position: absolute
                    bottom: 0
                    left: 50%
                    width: 0
                    height: 2px
                    background-color: $primary  // Couleur de la barre de soulignement
                    transition: width 0.3s ease-in-out, left 0.3s ease-in-out

                &:hover::before
                    width: 100%
                    left: 0
                &.active
                    color: $primary
                    border-bottom: solid 2px $primary
    .copy 
        background: #272626
        padding: 1rem
        margin-top: 2rem