// .ma-carte 
//     position: relative
//     width: 300px
//     height: 400px
//     .template
//         width: 300px
//         height: 400px
//         img
//             width: 100%
//             height: 100%
//         position: relative
//     .carte
//         padding-bottom: 1rem
//         top: 50%
//         left: 0
//         background: $white
//         width: 150px
//         width: 150px
//         position: absolute
//         transform: translate(3rem, -7rem)
//         img 
//             width: 100%
//     .carte-footer
//         background: $black
//         color: $white
//         margin: 0 -1rem
//         padding: 2rem
       
.ma-carte
    background-image: url("../../public/img/carte2.png")
    background-size: cover
    background-repeat: no-repeat
    // background: red
    width: 330px
    height: 500px
    display: flex
    align-items: center
    justify-content: center
    .info 
        background: $white
        padding-bottom: 1rem

.card-footer
    background: $black
    margin: 0 -1rem
    color: $white
    padding: 1rem 


